






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import { formatInstructorName } from "@/utils/NameUtil";

const CostBearerNoteModule = namespace("cost-bearer-note");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
  },
})
export default class CostBearerNotesPreview extends Vue {
  public name = "CostBearerNotesPreview";

  @CostBearerNoteModule.Action("findOne")
  public findCostBearerNoteById: any;

  @CostBearerNoteModule.Getter("getDataItem")
  public costBearerNote: any;

  @Prop()
  public note!: any;

  protected columns = [
    {
      label: this.$t("students.payers"),
      key: "costBearer",
    },
    // {
    //   label: this.$t("accounting.created_by"),
    //   key: "createdBy",
    // },
    {
      label: this.$tc("calendar.form_date"),
      key: "date"
    },
    {
      label: this.$t("calendar.note"),
      key: "note",
    },

  ];

  public mounted(): void {
    if (this.note) {
      this.findCostBearerNoteById({
        id: this.note.id,
        resource: "cost-bearer-note",
      });
    }
  }

  public get createdByFullName() {
    if (this.costBearerNote.createdBy) {
      return `${formatInstructorName(this.costBearerNote.createdBy.firstName, this.costBearerNote.createdBy.lastName)} / ${
        this.costBearerNote.createdBy.initials
      }`;
    }
    return "";
  }

  public get costBearerFullName() {
    if (this.costBearerNote.costBearer) {
      return `${this.costBearerNote.costBearer.name}`;
    }
    return "";
  }

  @Watch("note")
  public onNoteChange(note: any) {
    if (note) {
      this.findCostBearerNoteById({
        id: this.note.id,
        resource: "cost-bearer-note",
      });
    }
  }
}
