









































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEmail } from "@/interfaces/IEmail";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import HtmlViewer from "@/components/HtmlViewer.vue";

@Component({
  components: { HtmlViewer, FscSimpleCard },
})
export default class CostBearerMailServicePreview extends Vue {
  public name = "CostBearerMailServicePreview";

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop({ required: false, type: Object })
  public item!: IEmail;

  protected get subject(): string {
    return this.item ? this.item.subject : "";
  }

  protected get to(): string {
    return this.item ? this.item.to : "";
  }

  protected get body(): string {
    return this.item ? this.item.body : "";
  }

  protected get date(): string {
    return this.item ? this.item.date : "";
  }

  protected get time(): string {
    return this.item ? this.item.time : "";
  }

  protected get sender(): string {
    return this.item ? this.item.from : "";
  }

  public getIconNameByFileName(filename: string): string {
    const excelExtensions = ["xlsx", "xlsm", "xlsb", "xltx", "xltm", "xls", "xlt", "xls", "xml", "xlam", "xla", "xlw", "xlr"];
    const wordExtensions = ["doc", "docx", "dotx", "docm"];

    const fileExt = filename.split(".").pop();
    if (fileExt == "pdf") {
      return "file-pdf";
    } else if (excelExtensions.includes(fileExt || "")) {
      return "file-excel";
    } else if (wordExtensions.includes(fileExt || "")) {
      return "file-word";
    }
    return "file";
  }
}
