























import { Component, Prop, Vue } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: {
    AbortButton,
    SaveButton,
  },
})
export default class CostBearerServiceModal extends Vue {
  public name = "CostBearerServiceModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop({ required: true })
  protected radioOptions!: any;

  public radioSelected = "";

  public onAbortModal(): void {
    this.radioSelected = "";
    this.$emit("abort-modal");
  }

  public onSubmitModal(): void {
    this.$emit("submit-modal", this.radioSelected);
  }
}
