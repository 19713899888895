



































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import DocumentButton from "@/components/Button/DocumentButton.vue";
import { namespace } from "vuex-class";
import { formatInstructorName, formatStudentName } from "@/utils/NameUtil";

const ProofOfTrainingModule = namespace("proof-of-training");

@Component({
  components: {
    FscSimpleCard,
    FscHorizontalTable,
    DocumentButton,
  },
})
export default class CostBearerProofOfTrainingPreview extends Vue {
  public name = "CostBearerProofOfTrainingPreview";

  @ProofOfTrainingModule.Action("findOne")
  public findProofOfTrainingById: any;

  @ProofOfTrainingModule.Getter("getDataItem")
  public proofOfTrainingById: any;

  @ProofOfTrainingModule.Action("findStudentDocument")
  public findStudentDocument: any;

  @ProofOfTrainingModule.Getter("getMimeType")
  public mimeType: any;

  @ProofOfTrainingModule.Getter("getDataItemBlob")
  public blob: any;

  @Prop()
  public proofOfTraining!: any;

  @Prop()
  public documentPreviewButtonDisable!: any;

  @Prop({ type: String })
  public createdByName!: string;

  public sourceSignature = "";

  protected columns = [
    {
      label: this.$tc("general.customer", 1),
      key: "student",
    },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    {
      label: this.$tc("calendar.form_date", 1),
      key: "date",
    },
    {
      label: this.$tc("calendar.form_time", 1),
      key: "time",
    },
    {
      label: this.$t("general.signature"),
      key: "signature",
    },
  ];

  public removeLetterHead = false;

  public get studentFullName(): string {
    return this.proofOfTraining.student ? formatStudentName(this.proofOfTraining.student.firstName, this.proofOfTraining.student.lastName) : "";
  }

  public onProofOfTrainingDocumentPreview(): void {
    if (this.proofOfTrainingById.pdfDocumentId) {
      this.$emit("proof-doc-preview", this.proofOfTrainingById.pdfDocumentId, this.removeLetterHead);
    }
  }

  @Watch("proofOfTraining", { immediate: true, deep: true })
  public async onProofOfTrainingChange(proofOfTraining: any): Promise<void> {
    if (proofOfTraining) {
      await this.findProofOfTrainingById({
        id: proofOfTraining.id,
        resource: "proof-of-training",
      });
    }
    if (this.proofOfTrainingById && this.proofOfTrainingById.studentSignatureDocumentId) {
      await this.findStudentDocument(this.proofOfTrainingById.studentSignatureDocumentId);
    } else {
      this.sourceSignature = "";
    }
  }

  @Watch("blob")
  public onStudentDocumentChange(blob: any) {
    if (blob) {
      this.sourceSignature =
        "data:" +
        this.mimeType +
        ";base64," +
        //@ts-ignore
        new Buffer(blob, "binary").toString("base64");
    }
  }
}
