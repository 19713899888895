








































































import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Validation from "@/components/Validation.vue";
import Datepicker from "@/components/Datepicker.vue";
import { Validations } from "vuelidate-property-decorators";
import { maxLength, required } from "vuelidate/lib/validators";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { IBasicNamed } from "@/interfaces/IBasicNamed";
import FscMultiselect from "@/components/FscMultiselect.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import { mixins } from "vue-class-component";

@Component({
  components: {
    SaveButton,
    AbortButton,
    FscMultiselect,
    FscInputDecimal,
    Datepicker,
    Validation,
    FscSimpleCard,
  },
})
export default class CostBearerBookingForm extends mixins(CostBearerMixin) {
  public name = "CostBearerBookingForm";

  protected date = null;
  protected priceInEur = null;
  protected freeText = "";
  protected vat = null;

  @Prop({ type: Array, required: true })
  public vats!: Array<IBasicNamed>;

  @Prop({ type: Number, required: true })
  public costBearerId!: number;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  @Validations()
  public validations(): Record<string, any> {
    return {
      date: { required },
      freeText: { required, maxLength: maxLength(45) },
      priceInEur: { required },
      vat: { required },
    };
  }

  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    this.$emit("on-submit", {
      date: this.date,
      freeText: this.freeText.length ? this.freeText : null,
      priceInEur: this.priceInEur,
      vat: this.vat,
      costBearerId: this.costBearerId,
    });
  }

  public resetFields(): void {
    this.$v.$reset();
    this.date = null;
    this.freeText = "";
    this.priceInEur = null;
    this.vat = null;
  }

  public mounted() {
    this.$emit("on-mounted");
  }

  public get freeTextLength() {
    if (this.freeText) {
      const length = this.freeText.length || 0;
      return 45 - length > 0 ? 45 - length : 0;
    }
    return 45;
  }
}
