
























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IEducationEvent } from "@/interfaces/IEducationEvent";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import EducationEventDetails from "@/views/Student/EducationEvent/Details/EducationEventDetails.vue";
import { ILivePayPayment } from "@/interfaces/ILivePayPayment";
import StatusIcon from "@/components/StatusIcon.vue";
import { CANCELLED, CHARGED_BACK, CUSTOMER_APPROVAL_DENIED, FAILED, PAID_OUT } from "@/constants/Statuses";
import RefreshButton from "@/components/Button/RefreshButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import FibuFixTransactionRequestMixin from "@/mixins/Request/FibuFixTransactionRequestMixin";
import { mixins } from "vue-class-component";
import { PaymentMethodEnum } from "@/enums/PaymentMethodEnum";
import FibuFixTransactionsModal from "@/views/Student/FibuFixTransactionsModal.vue";
import { MODULE_PAYMENT_WRITE } from "@/constants/Permissions";

@Component({
  components: {
    FibuFixTransactionsModal,
    ActionButton,
    RefreshButton,
    StatusIcon,
    EducationEventDetails,
    FscHorizontalTable,
    FscSimpleCard,
  },
})
export default class CostBearerLivePayDetails extends mixins(FibuFixTransactionRequestMixin) {
  public name = "CostBearerLivePayDetails";

  @Prop({ required: true })
  public educationEvent!: IEducationEvent;

  @Prop({ required: true })
  public item!: ILivePayPayment;

  @Prop({ required: false, type: Boolean })
  public loading!: boolean;

  @Prop({ required: false, type: Boolean })
  public educationLoading!: boolean;

  @Prop({ type: String })
  public createdByName!: string;

  public disableCancellation = false;
  public disableRefreshButton = false;

  protected columns = [
    {
      label: this.$tc("automatic_payment.creation_date", 1),
      key: "date",
    },
    {
      label: this.$tc("automatic_payment.booking_date", 1),
      key: "time",
    },
    {
      label: this.$tc("automatic_payment.has_fibu_fix_transaction", 1),
      key: "hasFibuFixTransaction",
      hide: (item: any) => {
        return item?.hasFibuFixTransaction == undefined || item?.hasFibuFixTransaction == null || !this.$can(MODULE_PAYMENT_WRITE);
      },
    },
    {
      label: "",
      key: "statusChanges",
    },
  ];

  //The timeout functionality is intended for disabling the refresh button for 5 sec.
  public refreshEmit() {
    this.$emit("refresh", this.educationEvent.id, this.item.id);
    this.disableRefreshButton = true;
    setTimeout(() => {
      this.disableRefreshButton = false;
    }, 5000);
  }

  protected statusType(status: any) {
    switch (status) {
      case FAILED:
        return true;
      case CANCELLED:
        return true;
      case CHARGED_BACK:
        return true;
      default:
        return false;
    }
  }

  protected get livePayImgSrc() {
    return require("@/assets/Payment_Live.png");
  }

  protected get isDeclined(): boolean {
    return Boolean(this.item && this.item.statusChanges.find((s: { status: string }) => s.status === CANCELLED));
  }

  protected get isFailed(): boolean {
    return Boolean(this.item && this.item.statusChanges.find((s: { status: string }) => s.status === FAILED));
  }

  protected get isChargedBack(): boolean {
    return Boolean(this.item && this.item.statusChanges.find((s: { status: string }) => s.status === CHARGED_BACK));
  }

  @Watch("item", { immediate: true })
  public onItemWatcher(item: any) {
    if (item && item.status) {
      this.disableCancellation = false;
      switch (item.status) {
        case CANCELLED:
          this.disableCancellation = true;
          break;
        case CHARGED_BACK:
          this.disableCancellation = true;
          break;
        case CUSTOMER_APPROVAL_DENIED:
          this.disableCancellation = true;
          break;
        case FAILED:
          this.disableCancellation = true;
          break;
        case PAID_OUT:
          this.disableCancellation = true;
          break;
        default:
          this.disableCancellation = false;
      }
    }
  }

  private fibufixTransactionsModalId = "fibufixLivePayTransactionsModalId";

  private get hasPermission() {
    return this.$can(this.MODULE_PAYMENT_WRITE);
  }

  private handleFibufixTransactions() {
    this.$bvModal.show(this.fibufixTransactionsModalId);
    this.getFibuFixTransactions(PaymentMethodEnum.LIVE_PAY, this.item.id);
  }

  private get fibufixItems() {
    return this.fibuFixTransactions;
  }

  private async resendFibufixTransactions() {
    await this.resendFibuFixTransactions(this.item.id, PaymentMethodEnum.LIVE_PAY);

    if (this.fibuFixResendTransactionSuccess) {
      this.$emit("refresh-table");
    }
  }

  private get showResendTransaction() {
    return this.item?.hasFibuFixTransaction !== undefined && this.item?.hasFibuFixTransaction !== null;
  }
}
